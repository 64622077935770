const replaceText = () => {
  //要素取得
  const getText = document.querySelectorAll('.js-textReplace');
  const getTextList = Array.prototype.slice.call(getText,0); 

  getTextList.forEach((elem) => {
    //テキスト取得
    let targetString = elem.textContent
    //置換
    let resultString = targetString.replace(/(\*\*)(.+?)(\*\*)/g, '<strong>$2<\/strong>')
    //出力
    elem.innerHTML = resultString
  })
}

export default replaceText